exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blether-tsx": () => import("./../../../src/pages/blether.tsx" /* webpackChunkName: "component---src-pages-blether-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-play-noughts-and-crosses-js": () => import("./../../../src/pages/play/noughts-and-crosses.js" /* webpackChunkName: "component---src-pages-play-noughts-and-crosses-js" */),
  "component---src-pages-play-pong-js": () => import("./../../../src/pages/play/pong.js" /* webpackChunkName: "component---src-pages-play-pong-js" */),
  "component---src-pages-play-pongtwoplayer-js": () => import("./../../../src/pages/play/pongtwoplayer.js" /* webpackChunkName: "component---src-pages-play-pongtwoplayer-js" */),
  "component---src-pages-play-snake-js": () => import("./../../../src/pages/play/snake.js" /* webpackChunkName: "component---src-pages-play-snake-js" */),
  "component---src-pages-play-tessellate-js": () => import("./../../../src/pages/play/tessellate.js" /* webpackChunkName: "component---src-pages-play-tessellate-js" */),
  "component---src-pages-project-team-generator-js": () => import("./../../../src/pages/project/team-generator.js" /* webpackChunkName: "component---src-pages-project-team-generator-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

